import { useLocation, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.scoped.css'

import ICON_DOCUMENT_PLUS from '../../assets/icons/document-plus.svg'
import ICON_DOCUMENT_PLUS_ACTIVE from '../../assets/icons/document-plus-active.svg'
import ICON_FOLDER from '../../assets/icons/folder.svg'
import ICON_FOLDER_ACTIVE from '../../assets/icons/folder-active.svg'
import ICON_MAP from '../../assets/icons/map.svg'
import ICON_MAP_ACTIVE from '../../assets/icons/map-active.svg'

import { SCREENS } from '../../routes'
import { uniqueId } from 'lodash'
import { memo } from 'react'

function Footer(props: any) {
  const route = useLocation()
  const navigate = useNavigate()

  const tabs = [
    {
      name: SCREENS.HOME.NAME,
      icon: <img src={ICON_DOCUMENT_PLUS} alt="목업" />,
      iconActive: <img src={ICON_DOCUMENT_PLUS_ACTIVE} alt="목업 페이지" />,
      route: SCREENS.HOME.PATH,
    },
    {
      name: SCREENS.PROJECT.NAME,
      icon: <img src={ICON_FOLDER} alt="프로젝트" />,
      iconActive: <img src={ICON_FOLDER_ACTIVE} alt="프로젝트 페이지" />,
      route: SCREENS.PROJECT.PATH,
    },
    {
      name: SCREENS.HELP.NAME,
      icon: <img src={ICON_MAP} alt="사용 방법" />,
      iconActive: <img src={ICON_MAP_ACTIVE} alt="사용방법 페이지" />,
      route: SCREENS.HELP.PATH,
    },
  ]

  const handleClick = (tab: any) => {
    navigate(tab.route)
  }

  return (
    <div
      className="footer tabs flex flex-row justify-around"
      style={{ zIndex: 10 }}
    >
      {tabs.map((tab) => {
        const isActive = tab.route === route.pathname ? 'active' : ''

        return (
          <div
            key={`tab${uniqueId()}`}
            className={`tab flex flex-col justify-end items-center cursor-pointer ${isActive}`}
            onClick={() => handleClick(tab)}
          >
            <div className="icons flex flex-row justify-center">
              {isActive ? tab.iconActive : tab.icon}
            </div>
            <span className="name">{tab.name}</span>
          </div>
        )
      })}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(memo(Footer))
