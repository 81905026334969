import { uniqueId } from 'lodash'
import './styles.css'
import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'


export const ICON_POSITIONS = {
  START: 'START',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  END: 'END',
}

export interface ButtonIconPropTypes {
  position?: string
  icon?: any
}
export interface ButtonPropTypes {
  className?: string
  textClassName?: string
  text?: string
  icons?: Array<ButtonIconPropTypes>
  type?: string | number

  onClick?: () => void
}

const Button = (props: ButtonPropTypes) => {
  const handleClick = () => {
    if (props.onClick) props.onClick()
  }

  const renderIcon = (position: string) => {
    return (
      <>
        {props.icons?.map((icon) =>
          icon.position === position ? (
            <div key={`icon${uniqueId()}`}>{icon.icon()}</div>
          ) : null,
        )}
      </>
    )
  }

  return (
    <div
      className={`button cursor-pointer flex flex-row ${props.className}`}
      onClick={() => {
        handleClick()
      }}
    >
      {renderIcon(ICON_POSITIONS.LEFT)}
      <span className={`button-text ${props.textClassName} `}>
        {props.text}
      </span>
      {renderIcon(ICON_POSITIONS.RIGHT)}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(Button)
