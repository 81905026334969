/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable quotes */

import { snakelize, camelize } from "../../../utils/object";

export default ($axios: any): any => ({
  // 목록
  async list(data: any, token: string) {
    return camelize(
      await $axios.get(
        `/file/list/${data.projectId}/${data.groupId}`,
        snakelize({
          params: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      )
    );
  },

  // 저장
  async store(data: any, token: string) {
    const formData = new FormData();
    if (data.projectId) {
      formData.append("projectId", data.projectId);
    }
    if (data.groupId) {
      formData.append("groupId", data.groupId);
    }

    data.files.forEach((file: File) => {
      formData.append("files", file);
    });

    return camelize(
      await $axios.post(
        `/file/upload/${data.projectId}/${data.groupId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );
  },
});
