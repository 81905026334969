import { connect } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.css'

import ICON_BACK from '../../assets/icons/arrow-left-black.svg'

import { useEffect, useState } from 'react'
import API from '../../api'
import { AxiosResponse } from 'axios'
import Alert from '../../plugins/swal'
import Group from '../../components/group'
import { uniqueId } from 'lodash'

const ProjectShow = (props: any) => {
  const params = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()

  const [groups, setGroups] = useState<any>()

  const fetchGroups = () => {
    API.Group.list(
      {
        projectId: params.projectId,
      },
      props.auth.token,
    )
      .then((response: AxiosResponse) => {
        setGroups(response.data.data)
      })
      .catch((error: any) => {
        Alert.fire({ text: error.response?.data?.message, icon: 'error' })
      })
  }

  useEffect(() => {
    fetchGroups()
  }, [])

  const renderBreadCrumbs = () => {
    return (
      <div className="flex flex-row">
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate(-1)
          }}
        >
          <img src={ICON_BACK} alt="뒤로가기" />
        </div>
      </div>
    )
  }

  const renderPageGuide = () => {
    return (
      <div className="flex flex-col gap-1">
        <span className="group-guide-text">{state.project.name}</span>
      </div>
    )
  }

  return (
    <div className="page-project-show page-container flex flex-col justify-between h-full animate__animated animate__fadeIn">
      <div className="flex flex-col p-6">
        <div className="flex flex-col">
          <div className="flex flex-row">{renderBreadCrumbs()}</div>
          <div className="flex flex-row mt-4">{renderPageGuide()}</div>
        </div>
        <div className="flex flex-col mt-3 gap-4">
          {groups && groups.length
            ? groups.map((group: any) => {
                return (
                  <Group
                    key={`group${uniqueId()}`}
                    project={state.project}
                    group={group}
                  />
                )
              })
            : null}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(ProjectShow)
