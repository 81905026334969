import './styles.css'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../../store/store'

import API from '../../../api'
import { AxiosResponse } from 'axios'

import IMAGE_BOOKS from '../../../assets/images/books.svg'
import { uniqueId } from 'lodash'

import Project from '../../../components/project'
import Alert from '../../../plugins/swal'

const ProjectTotal = (props: any) => {
  const [projects, setProjects] = useState<any>()

  const fetchProjects = () => {
    API.Project.list({}, props.auth.token)
      .then((response: AxiosResponse) => {
        setProjects(response.data.data)
      })
      .catch((error: any) => {
        switch (error.response.data.statusCode) {
          case 404:
            setProjects([])
            break
          default:
            Alert.fire({ text: error.response?.data?.message, icon: 'error' })
            break
        }
      })
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  const renderEmptyContent = () => {
    return (
      <div className="flex flex-col justify-center items-center min-h-[480px]">
        <div className="mb-6">
          <img src={IMAGE_BOOKS} alt="책 더미" />
        </div>
        <div className="flex flex-row justify-center">
          <span className="project-recent-guide-text">
            생성된 프로젝트가 없어요
            <br />
            목업을 한번 제작해보세요
          </span>
        </div>
      </div>
    )
  }

  const renderProjects = () => {
    return projects ? (
      projects.length === 0 ? (
        renderEmptyContent()
      ) : (
        <div className="flex flex-col gap-4">
          {projects.map((project: any) => {
            return (
              <div key={`project${uniqueId()}`} className="flex flex-col">
                <Project project={project} />
              </div>
            )
          })}
        </div>
      )
    ) : null
  }

  return (
    <div className="flex flex-col w-full h-full justify-center gap-5 animate__animated animate__fadeIn">
      {renderProjects()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(ProjectTotal)
