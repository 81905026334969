class ChannelService {
  constructor() {
    this.loadScript()
  }

  loadScript() {
    var w = window
    if (w.ChannelIO) {
      return w.console.error('ChannelIO script included twice.')
    }
    var ch = function () {
      ch.c(arguments)
    }
    ch.q = []
    ch.c = function (args) {
      ch.q.push(args)
    }
    w.ChannelIO = ch
    function l() {
      if (w.ChannelIOInitialized) {
        return
      }
      w.ChannelIOInitialized = true
      var s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'
      var x = document.getElementsByTagName('script')[0]
      if (x.parentNode) {
        x.parentNode.insertBefore(s, x)
      }
    }
    if (document.readyState === 'complete') {
      l()
    } else {
      w.addEventListener('DOMContentLoaded', l)
      w.addEventListener('load', l)
    }
  }

  boot(callback) {
    window.ChannelIO(
      'boot',
      {
        pluginKey: 'fc36f675-3e9f-443d-bc87-e62ed6cb131a',
        customLauncherSelector: '.button', // 커스텀 버튼의 클래스명을 작성한다.
      },
      (error, user) => {
        console.log(user)
        // callback(user.alert) // callback함수에 사용자의 alert 값을 전달한다.
      },
    )
    return window.ChannelIO
  }

  shutdown() {
    window.ChannelIO('shutdown')
  }

  show() {
    window.ChannelIO('showChannelButton')
  }

  hide() {
    window.ChannelIO('hideChannelButton')
  }
}

export default new ChannelService()
