import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setCookie = (name: string, value: string, option?: any) => {
  return cookies.set(name, value, {
    ...{
      httpOnly: true,
      secure: true,
      // maxAge: 1000 * 60 * 60 * 24 * 30,
      sameSite: "none",
      domain: `www.tospace3d.com`,
    },
    ...option,
  });
};

export const getCookie = (name: string) => {
  return cookies.get(name);
};
