/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable quotes */

import { snakelize, camelize } from "../../../utils/object";

const headers = {
  headers: { "Content-Type": `application/json` },
};

export default ($axios: any): any => ({
  // 목록
  async show(data: any, token: string) {
    return camelize(
      await $axios.get(
        `/user`,
        snakelize({
          params: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      )
    );
  },
});
