import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.scoped.css'

import ICON_ARROW_RIGHT from '../../assets/icons/arrow-right-gray.svg'

interface ProjectPropTypes {
  name?: string

  auth?: any

  onClick?: () => void
}

function ListItem(props: ProjectPropTypes) {
  const handleClick = () => {
    if (props.onClick) props.onClick()
  }

  return (
    <div className={`group flex flex-col cursor-pointer`} onClick={handleClick}>
      <div className="flex flex-row w-full items-center p-4">
        <span className="group-created-at">{`${props.name}`}</span>
        <div className="ml-auto">
          <img src={ICON_ARROW_RIGHT} alt="열기" />
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(ListItem)
