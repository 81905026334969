import * as TYPES from './types'

const initialState = {
  common: {
    shareDomain: 'https://share.presendlite.com',
    currentHomeTab: { name: '신규 프로젝트' },
    currentProjectTab: { name: '최근 프로젝트' },
    isLoading: false,
  },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_COMMON:
      return {
        ...state,
        common: { ...state.common, ...action.payload },
      }

    default:
      return state
  }
}
export default reducer
