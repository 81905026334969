export const getDate = (time: any, forHuman: boolean = true) => {
  const year = new Date(time).getFullYear();
  const month = new Date(time).getMonth();
  const date = new Date(time).getDate();

  const hour = new Date(time)
    .getHours()
    .toString()
    .padStart(2, "0");
  const minute = new Date(time)
    .getMinutes()
    .toString()
    .padStart(2, "0");
  const second = new Date(time)
    .getSeconds()
    .toString()
    .padStart(2, "0");

  if (!forHuman) {
    return `${year}.${month}.${date} / ${hour}:${minute}:${second}`;
  }
  return `${year}년 ${month}월 ${date}일 / ${hour}:${minute}:${second}`;
};
