import ReactDOM from "react-dom/client";

import "./index.css";
import "./styles/global.css";
import "animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@google/model-viewer";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { CookiesProvider } from "react-cookie";

import store from "./store/store";
import Route from "./routes";
import EventManager from "./eventManager";
// import type { ModelViewerElement } from '@r2u/react-ar-components'

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById(
  "root"
) as HTMLElement);

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "model-viewer": any;
    }
  }
}

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CookiesProvider>
        <BrowserRouter>
          <EventManager />
          <Route />
        </BrowserRouter>
      </CookiesProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
