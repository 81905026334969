import './styles.css'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { mapDispatchProps, mapStateToProps } from '../../../store/store'

import Button from '../../../components/button'
import Input from '../../../components/input'
import API from '../../../api'
import { AxiosResponse } from 'axios'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { SCREENS } from '../../../routes'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { debounce } from 'lodash'
import Alert from '../../../plugins/swal'
import { getDate } from '../../../utils/dateFormat'

const GroupPassword = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [password, setPassword] = useState<string | any>('')

  const params = useParams()
  const [searchParams] = useSearchParams()

  const projectId = useRef<any>('')
  const groupId = useRef<any>('')

  const submitPassword = (input?: any) => {
    API.Group.auth({
      groupId: params.groupId,
      password: input ? input : password,
    })
      .then((response: AxiosResponse) => {
        projectId.current = response.data.data.projectId
        groupId.current = params.groupId

        API.Project.show({
          projectId: projectId.current,
        })
          .then((response: AxiosResponse) => {
            navigate(SCREENS.GROUP.PATH, {
              state: {
                title: `${getDate(response.data.data.createdAt, false)} 업로드`,
                description: response.data.data.name,
                projectId: projectId.current,
                groupId: groupId.current,
              },
            })
          })
          .catch((error: any) => {
            Alert.fire({ text: '프로젝트 정보를 불러오는데 실패하였습니다.' })
          })
      })
      .catch((error: any) => {
        Alert.fire({ text: '비밀번호가 일치하지 않습니다.' })
      })
  }

  useEffect(() => {
    if (searchParams.get('password')) {
      submitPassword(searchParams.get('password'))
    }
  }, [])

  return (
    <div className="page-container pt-[100px] bg-[#F6F8F9]">
      <div className="flex flex-col justify-center mt-3 gap-1 mb-8">
        <span className="text-center text-lg tracking-tight font-bold">
          링크 비밀번호 입력
        </span>
        <span className="text-center text-xs tracking-tight">
          전달받은 비밀번호를 입력해주세요
        </span>
      </div>

      <div className="flex flex-col px-6">
        <Input
          className="input-password"
          placeholder={'비밀번호를 입력해주세요'}
          type={'password'}
          onUpdate={(event: any) => {
            setPassword(event.target.value)
          }}
        />

        <Button
          className={`mt-4 ${password.length ? 'primary' : ''}`}
          text="확인"
          onClick={() => {
            submitPassword()
          }}
        />
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(GroupPassword)
