/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable quotes */
import { snakelize, camelize } from "../../../utils/object";

export default ($axios: any): any => ({
  // 생성
  async create(data: any, token: string) {
    const formData = new FormData();
    if (data.id) {
      formData.append("id", data.id);
    }
    if (data.name) {
      formData.append("name", data.name);
    }

    data.files.forEach((file: File) => {
      formData.append("files", file);
    });

    return camelize(
      await $axios.post(`/project`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": `application/octet-stream`,
          // "Content-Type": `multipart/form-data`,
        },
      })
    );
  },

  // 목록
  async list(data: any, token: string) {
    return camelize(
      await $axios.get(
        `/project/list`,
        snakelize({
          params: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      )
    );
  },

  // 최근 프로젝트
  async recent(data: any, token: string) {
    return camelize(
      await $axios.get(
        `/project/recent`,
        snakelize({
          params: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      )
    );
  },

  // 최근
  async files(data: any) {
    return camelize(
      await $axios.get(`/project/files`, snakelize({ params: data }))
    );
  },

  // 조회
  async show(data: any, token: string) {
    return camelize(await $axios.get(`/project/${data.projectId}`));
  },
});
