import { AxiosResponse } from 'axios'

import { mapDispatchProps, mapStateToProps } from '../../../store/store'
import { useRef, useState } from 'react'
import API from '../../../api'
import Button from '../../../components/button'

import Input from '../../../components/input'
import { TYPE as INPUT_TYPES } from '../../../components/input'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SCREENS } from '../../../routes'
import Alert from '../../../plugins/swal'
import Loader from '../../../components/loader'

const MockupCreate = (props: any) => {
  const navigate = useNavigate()
  const projectName = useRef<any>()
  const fileToUpload = useRef<any>([])

  const [isCompleted, setIsCompleted] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const getIsCompleted = () => {
    if (
      projectName &&
      projectName.current &&
      fileToUpload.current &&
      fileToUpload.current.length &&
      projectName.current.length <= 23
    ) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }
  }

  const submitGroup = () => {
    if (!projectName.current || !projectName.current.length) {
      Alert.fire({ text: '프로젝트 명을 입력해주세요', icon: 'warning' })
      return false
    }

    if (projectName.current.length > 23) {
      Alert.fire({
        text: '프로젝트 명은 23자를 초과할 수 없습니다.',
        icon: 'warning',
      })
      return false
    }

    if (!fileToUpload.current || !fileToUpload.current.length) {
      Alert.fire({ text: '업로드할 파일을 선택해주세요', icon: 'warning' })
      return false
    }

    if (fileToUpload.current.length > 3) {
      Alert.fire({
        text: '시안은 최대 3개 까지만 선택가능합니다',
        icon: 'warning',
      })
      return false
    }

    
    setIsLoading(true);
    API.Project.create(
      {
        name: projectName.current,
        files: fileToUpload.current,
      },
      props.auth.token,
    )
      .then((response: AxiosResponse) => {
        navigate(SCREENS.GROUP.PATH, {
          state: {
            projectId: response.data.data.projectId,
            groupId: response.data.data.groupId,
            share: true,
            add: true,
          },
        })
      })
      .catch((error: any) => {
        Alert.fire({ text: error.response?.data?.message, icon: 'error' })
      }).finally(()=>{
        setIsLoading(false);
      })
  }

  return (
    <div className="flex flex-col gap-5  animate__animated animate__fadeIn">
      <Input
        bordered
        label="프로젝트 명"
        placeholder="23자 이내로 입력해주세요"
        maxLength={23}
        onUpdate={(event: any) => {
          projectName.current = event.target.value
          getIsCompleted()
        }}
      />

      <Input
        label="3D 파일 업로드"
        type={INPUT_TYPES.FILE}
        append={true}
        maxLength={3}
        onUpdate={(event: any, value: any) => {
          fileToUpload.current = value
          getIsCompleted()
        }}
      />

      <Button
        onClick={submitGroup}
        className={`px-2 py-3 ${isCompleted ? 'completed' : ''}`}
        text={`AR 목업 생성`}
      />

      <Loader isVisible={isLoading} />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(MockupCreate)
