import axios from "axios";
import setupInterceptors from "../api/interceptors";
import store from "../store/store";

import * as authAction from "../store/auth/actions";
import { setCookie } from "../utils/cookie";

let instance = axios.create({
  // baseURL: "/api/",
  baseURL: "https://api.tospace3d.com/api/",
  withCredentials: true,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});
instance.defaults.withCredentials = true;

setupInterceptors(instance);

export const refreshToken = async () => {
  axios.defaults.withCredentials = true;
  // const url = `/api/auth/refresh`;
  const url = `https://api.tospace3d.com/api/auth/refresh`;
  return await axios
    .get(`${url}`, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        credentials: "include",
      },
    })
    .then((response) => {
      store.dispatch(
        authAction.setAuth({
          token: response.data.data.access_token,
        })
      );

      setCookie("access_token", response.data.data.access_token);

      return Promise.resolve(response);
    })
    .catch((error) => {
      store.dispatch(
        authAction.setAuth({
          token: null,
          refreshToken: null,
          isAuthenticated: false,
        })
      );
    });
};

export default instance;
