import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { AxiosResponse } from 'axios'
import API from '../api'
import { mapDispatchProps, mapStateToProps } from '../store/store'
import channelIO from '../plugins/channelIO'

const EventManager = (props: any) => {
  const user = useSelector((state: any) => state.user.user)
  const auth = useSelector((state: any) => state.auth.auth)
  const route = useLocation()

  const fetchUser = () => {
    API.User.show({}, auth.token).then((response: AxiosResponse) => {
      if (JSON.stringify(user) !== JSON.stringify(response.data.data)) {
        props.setUser(response.data.data)
      }
    })
  }

  useEffect(() => {
    channelIO.boot()
  }, [])

  useEffect(() => {
    if (auth && auth.token) {
      fetchUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route])

  return <></>
}

export default connect(mapStateToProps, mapDispatchProps)(EventManager)
