import { connect, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import DefaultLayout from '../layouts/index'
import AuthLayout from '../layouts/auth'
import BlankLayout from '../layouts/blank'

import HomeIndex from '../pages/home'
import SignInIndex from '../pages/signIn'
import ProjectIndex from '../pages/project'

import ProjectShow from '../pages/project/show'
import HelpIndex from '../pages/help'
import GroupIndex from '../pages/group'
import GroupPasswordIndex from '../pages/group/password'
import FileIndex from '../pages/file'
import React from 'react'
import { mapDispatchProps, mapStateToProps } from '../store/store'

export const SCREENS = {
  SIGN_IN: {
    PATH: '/',
    LAYOUT: AuthLayout,
    SCREEN: SignInIndex,
  },

  HOME: {
    PATH: '/',
    NAME: '목업 제작',
    LAYOUT: DefaultLayout,
    SCREEN: HomeIndex,
  },

  PROJECT: {
    PATH: '/projects',
    NAME: '프로젝트',
    LAYOUT: DefaultLayout,
    SCREEN: ProjectIndex,
  },

  PROJECT_SHOW: {
    PATH: (projectId?: string) => {
      if (projectId) return `/projects/${projectId}`
      return '/projects/:projectId'
    },
    LAYOUT: BlankLayout,
    SCREEN: ProjectShow,
  },

  GROUP: {
    PATH: '/groups',
    LAYOUT: BlankLayout,
    SCREEN: GroupIndex,
  },

  GROUP_PASSWORD: {
    PATH: '/groups/password/:groupId',
    LAYOUT: BlankLayout,
    SCREEN: GroupPasswordIndex,
  },

  FILE: {
    PATH: '/files',
    LAYOUT: BlankLayout,
    SCREEN: FileIndex,
  },

  HELP: {
    PATH: '/help',
    NAME: '사용방법',
    LAYOUT: DefaultLayout,
    SCREEN: HelpIndex,
  },
}

const Page = (props: any) => {
  const Screen = (PROPS: any) => {
    return (
      <React.Fragment>
        <PROPS.SCREEN.LAYOUT>
          <PROPS.SCREEN.SCREEN />
        </PROPS.SCREEN.LAYOUT>
      </React.Fragment>
    )
  }

  return <Screen {...props} />
}

const Router = (props: any) => {
  return (
    <>
      <Routes>
        {props.auth?.isAuthenticated ? (
          <>
            <Route
              path={SCREENS.HOME.PATH}
              element={<Page SCREEN={SCREENS.HOME} />}
            />
            <Route
              path={SCREENS.PROJECT.PATH}
              element={<Page SCREEN={SCREENS.PROJECT} />}
            />

            <Route
              path={SCREENS.PROJECT_SHOW.PATH()}
              element={<Page SCREEN={SCREENS.PROJECT_SHOW} />}
            />

            <Route
              path={SCREENS.HELP.PATH}
              element={<Page SCREEN={SCREENS.HELP} />}
            />
            <Route
              path={SCREENS.FILE.PATH}
              element={<Page SCREEN={SCREENS.FILE} />}
            />
          </>
        ) : null}
        <Route
          path={SCREENS.GROUP_PASSWORD.PATH}
          element={<Page SCREEN={SCREENS.GROUP_PASSWORD} />}
        />

        <Route
          path={SCREENS.GROUP.PATH}
          element={<Page SCREEN={SCREENS.GROUP} />}
        />

        <Route
          path={SCREENS.FILE.PATH}
          element={<Page SCREEN={SCREENS.FILE} />}
        />

        <Route path={'*'} element={<Page SCREEN={SCREENS.SIGN_IN} />} />
      </Routes>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(Router)
