import * as TYPES from './types'

const initialState = {
  auth: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_AUTH:
      return {
        ...state,
        auth: { ...state.auth, ...action.payload },
      }
    case TYPES.LOGOUT:
      return {
        ...state,
        auth: {},
        user: {},
      }
    default:
      return state
  }
}

export default reducer
