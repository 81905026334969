import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Tabs from '../../components/tabs'
import { TabTypes } from '../../components/tabs'

import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.scoped.css'

import MockupIndex from './mockup/index'
import MockupCreate from './mockup/create'

import Welcome from '../../components/welcome'

const HomeScreen = (props: any) => {
  const [menus, setMenus] = useState([
    { name: '신규 프로젝트' },
    { name: '기존 프로젝트' },
  ])

  return (
    <div className="page-container">
      <div className="pt-10">
        <Welcome />
      </div>

      <div className="mt-3">
        <Tabs
          tabs={menus}
          selected={props?.common?.currentHomeTab}
          onTabUpdated={(tab: TabTypes) => {
            props.setCommon({ currentHomeTab: tab })
          }}
        />
      </div>

      <div className="flex flex-col p-6">
        <TabContent currentTab={props?.common?.currentHomeTab} {...props} />
      </div>
    </div>
  )
}

interface TabContentTypes {
  currentTab?: TabTypes
  productName?: any
  file?: any
  children?: any

  common?: any
}

const TabContent = (props: TabContentTypes) => {
  const renderContent = useCallback(() => {
    switch (props?.common?.currentHomeTab?.name) {
      case '신규 프로젝트':
        return <MockupCreate {...props} />

      case '기존 프로젝트':
        return <MockupIndex {...props} />
    }

    return <></>
  }, [props.common.currentHomeTab])

  return renderContent()
}

export default connect(mapStateToProps, mapDispatchProps)(HomeScreen)
