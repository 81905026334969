import { useCallback, useState } from 'react'
import { connect } from 'react-redux'

import Tabs from '../../components/tabs'
import { TabTypes } from '../../components/tabs'

import { mapDispatchProps, mapStateToProps } from '../../store/store'

import ProjectRecent from './recent'
import ProjectTotal from './total'

import './styles.css'

const ProjectScreen = (props: any) => {
  const [menus, setMenus] = useState([
    { name: '최근 프로젝트' },
    { name: '모든 프로젝트' },
  ])

  return (
    <div className="page-container w-full h-full">
      <div className="mt-3">
        <Tabs
          tabs={menus}
          selected={props?.common?.currentProjectTab}
          onTabUpdated={(tab: TabTypes) => {
            props.setCommon({ currentProjectTab: tab })
          }}
        />
      </div>
      <div className="flex flex-col p-6">
        <TabContent currentTab={props?.common?.currentProjectTab} {...props} />
      </div>
    </div>
  )
}

interface TabContentTypes {
  currentTab?: TabTypes
  productName?: any
  file?: any
  children?: any

  common?: any
}

const TabContent = (props: TabContentTypes) => {
  const renderContent = useCallback(() => {
    switch (props?.common?.currentProjectTab?.name) {
      case '최근 프로젝트':
        return <ProjectRecent />

      case '모든 프로젝트':
        return <ProjectTotal />
    }

    return <></>
  }, [props?.common?.currentProjectTab])

  return renderContent()
}

export default connect(mapStateToProps, mapDispatchProps)(ProjectScreen)
