import './styles.css'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../../store/store'

import API from '../../../api'
import { AxiosResponse } from 'axios'

import IMAGE_BOOKS from '../../../assets/images/books.svg'
import { uniqueId } from 'lodash'

import Group from '../../../components/group'
import Alert from '../../../plugins/swal'

const ProjectRecent = (props: any) => {
  const [groups, setGroups] = useState<Array<any> | undefined>(undefined)
  const [project, setProject] = useState<any>()

  const fetchRecentProjects = () => {
    API.Project.recent({}, props.auth.token)
      .then((response: AxiosResponse) => {
        setGroups(response.data.data.groups)
        setProject(response.data.data.project)
      })
      .catch((error: any) => {
        switch (error.response.data.statusCode) {
          case 404:
            setGroups([])
            break
          default:
            Alert.fire({ text: error.response?.data?.message, icon: 'error' })
            break
        }
      })
  }

  useEffect(() => {
    fetchRecentProjects()
  }, [])

  const renderEmptyContent = () => {
    return (
      <div className="flex flex-col justify-center items-center min-h-[480px]">
        <div className="mb-6">
          <img src={IMAGE_BOOKS} alt="책 더미" />
        </div>
        <div className="flex flex-row justify-center">
          <span className="project-recent-guide-text">
            생성된 프로젝트가 없어요
            <br />
            목업을 한번 제작해보세요
          </span>
        </div>
      </div>
    )
  }

  const renderProject = () => {
    if (!project) return false
    if (!project.name) return false

    return (
      <div className="flex flex-col items-center gap-0.5">
        <span className="project-header-caption-text">프로젝트</span>
        <span className="project-header-name">{project.name}</span>
      </div>
    )
  }

  const renderGroups = () => {
    return groups ? (
      groups.length === 0 ? (
        renderEmptyContent()
      ) : (
        <div className="flex flex-col gap-4">
          {groups.map((group: any) => {
            return (
              <div key={`group${uniqueId()}`} className="flex flex-col">
                <Group project={project} group={group} />
              </div>
            )
          })}
        </div>
      )
    ) : null
  }

  return (
    <div className="flex flex-col w-full h-full justify-center gap-5 animate__animated animate__fadeIn">
      {renderProject()}
      {renderGroups()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(ProjectRecent)
