import { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.css'

import ICON_UPLOAD from '../../assets/icons/upload.svg'
import File from '../file'
import { uniqueId } from 'lodash'
import Button from '../button'
import Alert from '../../plugins/swal'
import { isEmpty } from '../../utils/object'

export const TYPE = {
  TEXT: 0,
  FILE: 1,
}

export interface InputPropTypes {
  className?: string

  label?: string
  value?: any
  defaultValue?: any
  maxLength?: number
  placeholder?: string
  type?: string | number

  bordered?: boolean

  onUpdate?: (event?: any, value?: any) => void

  clearTrigger?: number
}

const Input = (props: any) => {
  const inputRef = useRef<any>(null)
  const [value, setValue] = useState<any>(props.defaultValue)
  const [inputError, setInputError] = useState<any>({})
  const [type, setType] = useState(props.type ? props.type : TYPE.TEXT)

  const clearInput = () => {
    setValue(undefined)
    if (inputRef.current) inputRef.current.value = ''
  }

  useEffect(() => {
    if (props.clearTrigger) clearInput()
  }, [props.clearTrigger])

  useEffect(() => {
    if (props.type === TYPE.FILE && inputRef.current) {
      inputRef.current.value = ''
    }
  }, [value])

  const validateFilename = (event: any) => {
    const files = event.target.files
    const regex = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,@#]/
    let hasSpecialChar = false

    for (let i = 0; i < files.length; i++) {
      if (regex.test(files[i].name)) {
        hasSpecialChar = true
        break
      }
    }

    if (hasSpecialChar) {
      if (inputRef.current) inputRef.current.value = ''
    }

    return !hasSpecialChar
  }

  const handleUpdate = (event: any) => {
    switch (type) {
      case TYPE.FILE:
        const files = event.target.files
        const result: any = []

        if (value && props.append) {
          for (let i = 0; i < value.length; i++) {
            const file = value[i]
            result.push(file)
          }
        }

        for (let i = 0; i < files.length; i++) {
          const element = files[i]
          result.push(element)
        }

        if (validateFilename(event) === false) {
          Alert.fire({
            icon: 'warning',
            text: `파일이름에 특수문자를 포함할 수 없습니다.`,
          })
          return false
        }

        if (props.maxLength && result.length > props.maxLength) {
          Alert.fire({
            icon: 'warning',
            text: `한 번에 최대 ${props.maxLength}개만 지원하고 있습니다`,
          })
          return false
        }

        const dataTransfer = new DataTransfer()

        result.forEach((file: any) => {
          dataTransfer.items.add(file)
        })

        inputRef.current.files = dataTransfer.files

        setValue(value && props.append ? result : result)
        if (props.onUpdate) props.onUpdate(event, result)
        break

      default:
      case TYPE.TEXT:
        let validation = {}

        if (event.target.value.length > props.maxLength) {
          validation = { message: `${props.maxLength}자를 초과합니다.` }
        }

        setInputError(validation)

        setValue(event.target.value)
        if (props.onUpdate) props.onUpdate(event, event.target.value)
        break
    }
  }

  const renderError = useCallback(() => {
    return isEmpty(inputError) ? null : (
      <span className="mt-1 text-[#F2271C] text-sm">{inputError.message}</span>
    )
  }, [inputError])

  const renderLabel = useCallback(() => {
    return props.label ? (
      <span className="input-label mb-1">{props.label}</span>
    ) : null
  }, [props.label])

  const renderInput = useCallback(() => {
    const isBordered = props.bordered ? 'input-bordered' : null
    const hasError = !isEmpty(inputError) ? 'error' : null

    const renderEmptyContent = () => {
      if (value && !isEmpty(value)) {
        return false
      }

      return (
        <div
          className={`input-file flex flex-col justify-center items-center px-10 py-7 cursor-pointer ${props.className}`}
          onClick={() => {
            inputRef.current.click()
          }}
        >
          <div className="mb-3">
            <img src={ICON_UPLOAD} />
          </div>
          <div className="flex flex-col items-center justify-center">
            <span className="file-select-title text-center">
              GLB 파일을 업로드 해주세요
            </span>
          </div>
        </div>
      )
    }

    const renderContent = () => {
      switch (type) {
        case TYPE.FILE:
          if (!value) {
            return false
          }

          return (
            <>
              {value && value.length
                ? value.map((file: any) => {
                    return (
                      <File
                        key={`file${uniqueId()}`}
                        file={file}
                        onDelete={(fileToDelete: any) => {

                          const result = [...value.filter(
                            (item: any) => {
                              return item.name !== fileToDelete.name
                            },
                          )]

                          setValue(result)

                          if (props.onUpdate) props.onUpdate(null, result)
                        }}
                      />
                    )
                  })
                : null}
            </>
          )
      }
    }

    const renderAppendButton = () => {
      return value && props.append && value.length < props.maxLength ? (
        <Button
          className="slate mt-10 py-2 "
          text="시안추가"
          onClick={() => {
            inputRef.current.click()
          }}
        />
      ) : null
    }

    switch (type) {
      case TYPE.FILE:
        return (
          <>
            {renderEmptyContent()}
            {renderContent()}
            {renderAppendButton()}

            <input
              ref={inputRef}
              id={`input${uniqueId()}`}
              type={'file'}
              className="hidden"
              multiple={props.append ? true : false}
              defaultValue={props.defaultValue ?? props.defaultValue}
              onChange={handleUpdate}
            />
          </>
        )

      default:
        return (
          <>
            <input
              ref={inputRef}
              id={`input${uniqueId()}`}
              className={`input ${isBordered} ${hasError} ${props.className}`}
              defaultValue={props.defaultValue ?? props.defaultValue}
              placeholder={props.placeholder ?? props.placeholder}
              type={props.type ?? props.type}
              onChange={handleUpdate}
            />
          </>
        )
    }
  }, [props.defaultValue, props.type, props.value, value])

  const render = useCallback(() => {
    return (
      <div className="flex flex-col" ref={props.ref}>
        {renderLabel()}
        {renderInput()}
        {renderError()}
      </div>
    )
  }, [props.defaultValue, props.type, props.value, value])
  return render()
}

export default connect(mapStateToProps, mapDispatchProps)(Input)
