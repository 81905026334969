import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.scoped.css'

import ICON_ARROW_RIGHT from '../../assets/icons/arrow-right-gray.svg'
import { useNavigate } from 'react-router-dom'
import { SCREENS } from '../../routes'

interface ProjectPropTypes {
  project?: any
  auth?: any
}

function Project(props: ProjectPropTypes) {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(SCREENS.PROJECT_SHOW.PATH(`${props.project.id}`), {
      state: { project: props.project },
    })
  }

  return (
    <div className={`group flex flex-col cursor-pointer`} onClick={handleClick}>
      <div className="flex flex-row w-full items-center p-4" onClick={() => {}}>
        <span className="group-created-at">{`${props.project.name}`}</span>
        <div className="ml-auto">
          <img src={ICON_ARROW_RIGHT} alt="열기" />
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(Project)
