import './styles.css'
import { useCallback, useState } from 'react'
import { connect } from 'react-redux'

import Tabs from '../../components/tabs'
import { TabTypes } from '../../components/tabs'

import { mapDispatchProps, mapStateToProps } from '../../store/store'

import Button from '../../components/button'
import ListItem from '../../components/listItem'
import { uniqueId } from 'lodash'

const HelpScreen = (props: any) => {
  const [menus] = useState([
    { name: '이용 가이드', value: 'new' },
    { name: '상세 설정' },
  ])

  const [currentTab, setCurrentTab] = useState<TabTypes>(menus[0])

  const handleLogout = () => {
    props.logout()
  }

  const renderContent = useCallback(() => {
    switch (currentTab?.name) {
      case '이용 가이드':
        const guides = [
          { name: '서비스 소개' },
          { name: '사용 방법' },
          { name: '자주 묻는 질문' },
        ]

        return (
          <div className="flex flex-col gap-4">
            {guides.map((guide: any) => {
              return <ListItem key={`guide${uniqueId()}`} name={guide.name} />
            })}
          </div>
        )

      case '상세 설정':
        const preferences = [
          { name: '이용약관/개인정보처리방침' },
          { name: '공지사항' },
        ]

        return (
          <div className="flex flex-col gap-4">
            {preferences.map((guide: any) => {
              return (
                <ListItem key={`preferences${uniqueId()}`} name={guide.name} />
              )
            })}
            <Button className="danger" text="로그아웃" onClick={handleLogout} />
          </div>
        )
    }

    return <></>
  }, [currentTab])

  return (
    <div className="page-container">
      <div className="mt-3">
        <Tabs
          tabs={menus}
          onTabUpdated={(tab: TabTypes) => {
            setCurrentTab(tab)
          }}
        />
      </div>
      <div className="flex flex-col p-6">{renderContent()}</div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(HelpScreen)
