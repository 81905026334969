import { AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import API from '../../../api'
import Input from '../../../components/input'
import Select from '../../../components/select'
import { TYPE as INPUT_TYPES } from '../../../components/input'
import Button from '../../../components/button'
import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../../store/store'
import { useNavigate } from 'react-router-dom'
import { SCREENS } from '../../../routes'
import Alert from '../../../plugins/swal'
import Loader from '../../../components/loader'

const MockupIndex = (props: any) => {
  const navigate = useNavigate()
  const [projects, setProjects] = useState<any>()
  const [selectedProject, setSelectedProject] = useState<any>()
  const [fileToUpload, setFileToUpload] = useState<any>()
  const [isLoading, setIsLoading] = useState(false);

  const isCompleted = selectedProject && fileToUpload ? true : false

  const submitGroup = () => {
    if (!selectedProject) {
      Alert.fire({
        text: '파일을 추가할 프로젝트를 선택해주세요',
        icon: 'warning',
      })
      return false
    }

    if (!fileToUpload) {
      Alert.fire({
        text: '업로드할 파일을 선택해주세요',
        icon: 'warning',
      })
      return false
    }
    const files = Array.from(fileToUpload)
    if (!files) {
      Alert.fire({ text: '업로드할 파일을 선택해주세요', icon: 'warning' })
      return false
    }

    if (files.length > 3) {
      Alert.fire({
        text: '시안은 최대 3개까지만 선택할 수 있습니다',
        icon: 'warning',
      })
      return false
    }

    setIsLoading(true);
    API.Project.create(
      {
        id: selectedProject.id,
        files: files,
      },
      props.auth.token,
    )
      .then((response: AxiosResponse) => {
        navigate(SCREENS.GROUP.PATH, {
          state: {
            projectId: response.data.data.projectId,
            groupId: response.data.data.groupId,
            share: true,
            add: true,
          },
        })
      })
      .catch((error: any) => {
        Alert.fire({ text: error.response?.data?.message, icon: 'error' })
      }).finally(()=>{
    setIsLoading(false);
      })
  }

  const fetchProjects = (callback?: (projects: any) => void) => {
    API.Project.list({}, props.auth.token)
      .then((response: AxiosResponse) => {
        setProjects(response.data.data)
        if (callback) callback(response.data.data)
      })
      .catch((error: any) => {
        switch (error.response.data.statusCode) {
          case 404:
            setProjects([])
            break
          default:
            Alert.fire({ text: error.response?.data?.message, icon: 'error' })
            break
        }
      })
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  const handleProjectSelect = (project: any) => {
    setFileToUpload(undefined)
    setSelectedProject(project)
    clearInput(inputClearTrigger + 1)
  }

  const [inputClearTrigger, clearInput] = useState(0)

  const render = useCallback(() => {
    return (
      <div className="flex flex-col gap-5  animate__animated animate__fadeIn">
        <Select
          label="프로젝트 선택"
          placeholder="선택된 프로젝트 없음"
          bordered
          options={projects}
          onUpdate={(project: any) => {
            handleProjectSelect(project)
          }}
        />

        <Input
          label="3D 파일 업로드"
          type={INPUT_TYPES.FILE}
          append={true}
          maxLength={3}
          onUpdate={(event: any, value: any) => {
          setFileToUpload(value);
          }}
          clearTrigger={inputClearTrigger}
        />

        <Button
          onClick={submitGroup}
          className={`px-2 py-3 ${isCompleted ? 'completed' : ''}`}
          text={`AR 목업 생성`}
        />

        <Loader isVisible={isLoading} />
      </div>
    )
  }, [projects, selectedProject, fileToUpload])

  return render()
}

export default connect(mapStateToProps, mapDispatchProps)(MockupIndex)
