import axios from "../plugins/axios";

import Auth from "./endpoints/auth";
import Project from "./endpoints/project";
import Group from "./endpoints/group";
import File from "./endpoints/file";
import User from "./endpoints/user";

const API = ($axios: any) => ({
  Auth: Auth($axios),
  User: User($axios),
  Project: Project($axios),
  Group: Group($axios),
  File: File($axios),
});

export default API(axios);
