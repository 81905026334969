import * as TYPES from './types'

export const setAuth = (payload) => ({
  type: TYPES.SET_AUTH,
  payload,
})

export const logout = () => ({
  type: TYPES.LOGOUT,
})
