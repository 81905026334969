import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import logger from 'redux-logger'

import rootReducer from './rootReducer'

// actions
import * as authAction from './auth/actions'
import * as userAction from './user/actions'
import * as commonAction from './common/actions'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const middleWare = applyMiddleware(logger, thunk)

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'],
}

const enhancedReducer = persistReducer(persistConfig, rootReducer)

export const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    user: state.user.user,
    common: state.common.common,
  }
}

export const mapDispatchProps = (dispatch) => {
  return {
    // Auth
    setAuth: (payload) => {
      dispatch(authAction.setAuth(payload))
    },

    logout: () => {
      dispatch(authAction.logout())
    },

    setUser: (payload) => {
      dispatch(userAction.setUser(payload))
    },

    setCommon: (payload) => {
      dispatch(commonAction.setCommon(payload))
    },
  }
}

const store = createStore(enhancedReducer, composeWithDevTools(middleWare))
export default store
