import "./styles.css";

import { connect } from "react-redux";
import { mapDispatchProps, mapStateToProps } from "../../store/store";

import ICON_BACK from "../../assets/icons/arrow-left-black.svg";

// Components
import { useLocation, useNavigate } from "react-router-dom";
import Sheet from "../../components/sheet";
import QRCode from "react-qr-code";
import { useCallback, useEffect, useRef, useState } from "react";
import Alert from "../../plugins/swal";
import { AxiosResponse } from "axios";
import API from "../../api";

const FileIndex = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isQrSheetOpened, setIsQrSheetOpened] = useState(false);
  const viewerRef = useRef<any>(null);
  const shareLink = useRef();
  const sharePassword = useRef();

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const deviceType = isMobile ? "mobile" : "desktop";

    if (deviceType === "desktop" && !isQrSheetOpened) {
      setIsQrSheetOpened(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderBreadCrumbs = () => {
    return (
      <div className="flex flex-row w-full">
        <div className="flex flex-row w-full items-center justify-between">
          <div
            className="mr-4 cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={ICON_BACK} alt="뒤로가기" />
          </div>
          <div>
            <span className="pages-file-title-text">AR로 보기</span>
          </div>
          <div
            className="ml-auto cursor-pointer"
            onClick={() => {
              setIsQrSheetOpened(true);
            }}
          >
            <QRCode
              size={24}
              value={`${props.common.shareDomain}/groups/password/${
                state.groupId
              }?password=${state.groupId.substr(0, 6)}`}
            />
          </div>
        </div>
      </div>
    );
  };

  const activateAR = () => {
    viewerRef.current = document.querySelector("model-viewer");
    if (viewerRef.current.canActivateAR) {
      viewerRef.current.activateAR();
    }
  };

  const renderModel = useCallback(
    () => (
      <model-viewer
        AR
        style={{ width: "100%", height: "90dvh" }}
        src={state.glbLink}
        ios-src={state.usdzLink}
        ar-modes="webxr scene-viewer quick-look"
        poster={state.thumbnail}
        alt=""
        shadow-intensity="1"
        camera-controls
        touch-action="pan-y"
        ar-button={false}
      >
        <button slot="ar-button" style={{ opacity: 0, display: "none" }} />

        <button
          slot="default-ar-button"
          style={{ opacity: 0, display: "none" }}
        />
      </model-viewer>
    ),
    [state]
  );

  useEffect(() => {
    viewerRef.current = document.querySelector("model-viewer");
    viewerRef.current.addEventListener("load", () => {
      activateAR();
    });

    viewerRef.current.addEventListener("error", () => {
      activateAR();
    });

    setTimeout(() => {
      activateAR();
    }, 2500);
  }, []);

  const renderQrSheet = () => {
    const handleCopyClipBoard = async (text: string) => {
      try {
        await navigator.clipboard.writeText(text);

        Alert.fire({
          text: "클립보드에 링크가 복사되었습니다",
          icon: "success",
        });
      } catch (e) {
        Alert.fire({
          text: "복사에 실패하였습니다",
          icon: "warning",
        });
      }
    };

    return (
      <Sheet
        active={isQrSheetOpened}
        title="휴대폰에서 확인해주세요"
        description="아래 QR코드를 찍으면 바로 확인하실 수 있습니다"
        onHidden={() => {
          setIsQrSheetOpened(false);
        }}
        content={() => {
          return (
            <div className="overlay-content flex flex-col gap-3 mx-8 pt-3 pb-3 mb-6 items-center">
              <QRCode
                value={`${props.common.shareDomain}/groups/password/${
                  state.groupId
                }?password=${state.groupId.substr(0, 6)}`}
              />
            </div>
          );
        }}
        completed={true}
        submitText={`공유하기`}
        onSubmit={() => {
          API.Group.share(
            {
              projectId: state.projectId,
              groupId: state.groupId,
            },
            props.auth.token
          ).then((response: AxiosResponse) => {
            shareLink.current = response.data.data.link;
            sharePassword.current = response.data.data.password;

            if (navigator.share) {
              navigator
                .share({
                  title: "TOSPACE",
                  text:
                    `클라이언트나 동료에게 링크로 전달해보세요.${"\n"}` +
                    `비밀번호 : ${sharePassword.current}`,
                  url: `${shareLink.current}`,
                })
                .then(() => console.log("공유 성공"))
                .catch((error) => console.log("공유 실패", error));
            } else {
              handleCopyClipBoard(
                `${shareLink.current}?password=${sharePassword.current}`
              );
            }
          });
        }}
      />
    );
  };

  return (
    <div className="page-file page-container bg-[#F6F8F9]">
      <div className="flex flex-col p-6">
        <div className="flex flex-row">{renderBreadCrumbs()}</div>
        {renderModel()}
        {renderQrSheet()}
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(FileIndex);
