import { connect, useSelector } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.scoped.css'
import laptop from '../../assets/images/laptop.png'
import google from '../../assets/icons/google.svg'

import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'

import { auth as firebaseAuth } from '../../plugins/firebaseAuth'

import API from '../../api'
import { AxiosResponse } from 'axios'
import Alert from '../../plugins/swal'
import { setCookie } from '../../utils/cookie'

function SignInScreen(props: any) {
  const user = useSelector((state: any) => state.user.user)

  const fetchUser = (token: string) => {
    API.User.show(null, token).then((response: AxiosResponse) => {
      if (JSON.stringify(user) !== JSON.stringify(response.data.data)) {
        props.setUser(response.data.data)
      }
    })
  }

  const submitLogin = async (provider = 'google') => {
    try {
      const provider = new GoogleAuthProvider()
      let result: any = await signInWithPopup(firebaseAuth, provider)

      const params = {
        uid: result.user.uid,
        idToken: result._tokenResponse.idToken,
      }

      register(params, () => {
        login(params, (accessToken: any, refreshToken: any) => {
          setCookie('access_token', `${accessToken}`)
          setCookie('refresh_token', `${refreshToken}`)

          props.setAuth({
            ...params,
            ...{
              token: `${accessToken}`,
              refreshToken: `${refreshToken}`,
              isAuthenticated: true,
            },
          })

          fetchUser(`${accessToken}`)
        })
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const register = (params: any, callback: any) => {
    API.Auth.register(params)
      .then((response: AxiosResponse) => {
        if (callback) callback()
      })
      .catch((error: any) => {
        if (callback) callback()
      })
  }

  const login = (params: any, callback: any) => {
    API.Auth.login({ uid: params.uid })
      .then((response: AxiosResponse) => {
        if (callback)
          callback(
            response.data.data.accessToken,
            response.data.data.refreshToken,
          )
      })
      .catch((error: any) => {
        Alert.fire({ text: error.response?.data?.message, icon: 'error' })
      })
  }

  return (
    <div className="bg flex flex-col h-full justify-between items-center">
      <div className="service-title-container flex flex-col justify-center items-center">
        <h1 className="service-title">DesignAR</h1>
        <h1 className="service-description">10초만에 제작하는 AR 목업</h1>
      </div>

      <div className="laptop-container">
        {/* <img className="w-full h-full" src={laptop} alt="노트북 사진" /> */}
      </div>

      <div
        className="login-button cursor-pointer"
        onClick={() => submitLogin()}
      >
        <div>
          <img src={google} alt="구글 로고" />
        </div>
        <div>
          <span className="login-button-text">구글 로그인</span>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(SignInScreen)
