import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.scoped.css'

import ICON_ARROW_UP from '../../assets/icons/arrow-up-black.svg'
import ICON_ARROW_DOWN from '../../assets/icons/arrow-down-gray.svg'

import Collapsible from 'react-collapsible'
import { useState } from 'react'
import API from '../../api'
import { AxiosResponse } from 'axios'
import File from '../file'
import { uniqueId } from 'lodash'
import Button from '../button'
import { useNavigate } from 'react-router-dom'
import { SCREENS } from '../../routes'
import Alert from '../../plugins/swal'
import { getDate } from '../../utils/dateFormat'

interface GroupPropTypes {
  group?: any
  project?: any

  auth?: any
}

function Group(props: GroupPropTypes) {
  const navigate = useNavigate()
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [files, setFiles] = useState<any>()

  const fetchFiles = () => {
    if (files && files.length) {
      setIsOpened(!isOpened)
      return false
    }

    API.File.list(
      {
        projectId: props.project.id,
        groupId: props.group.id,
      },
      props.auth.token,
    )
      .then((response: AxiosResponse) => {
        const files = response.data.data.map((file: any) => {
          return { ...file, ...{ name: file.originalName } }
        })
        setFiles(files)
        setIsOpened(!isOpened)
      })
      .catch((error: any) => {
        Alert.fire({ text: error.response?.data?.message, icon: 'error' })
      })
  }

  const toggleAccordion = () => {
    fetchFiles()
  }

  return (
    <div className={`group flex flex-col ${isOpened ? '' : 'cursor-pointer'}`}>
      <div
        className="flex flex-row w-full items-center p-4"
        onClick={() => {
          toggleAccordion()
        }}
      >
        <span className="group-created-at">
          {`${getDate(props.group.createdAt)} 업로드`}
        </span>
        <div className="ml-auto">
          <img src={isOpened ? ICON_ARROW_UP : ICON_ARROW_DOWN} alt="열기" />
        </div>
      </div>

      <Collapsible
        trigger={''}
        open={isOpened}
        transitionTime={300}
        easing={'ease'}
        onClick={() => {
          toggleAccordion()
        }}
      >
        <div className="px-4 pb-1">
          {files && files.length
            ? files.map((file: any) => {
                return <File key={`file${uniqueId()}`} file={file} />
              })
            : null}
          <Button
            className="active"
            text="AR 보기"
            onClick={() => {
              navigate(SCREENS.GROUP.PATH, {
                state: {
                  projectId: props.project.id,
                  groupId: props.group.id,
                  files: files,
                  title: `${getDate(props.group.createdAt, false)} 업로드`,
                  description: props.project.name,
                  share: true,
                },
              })
            }}
          />
        </div>
      </Collapsible>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(Group)
