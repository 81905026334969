/* eslint-disable import/no-anonymous-default-export */
import axios, { AxiosInstance } from 'axios'
import { refreshToken } from '../../plugins/axios'

const NO_RETRY_HEADER = 'x-no-retry'

export default ($axios: AxiosInstance) => {
  $axios.interceptors.request.use((config) => {
    return config
  })

  $axios.interceptors.response.use(
    (response) => {
      return response
    },
    async (error: any) => {
      const originalRequest = error.config

      switch (error.response.status) {
        case 401:
          if (
            !axios.isCancel(error) &&
            axios.isAxiosError(error) &&
            error?.response?.status === 401
          ) {
            if (
              originalRequest.headers &&
              originalRequest.headers[NO_RETRY_HEADER]
            ) {
              return Promise.reject(error)
            }

            const newTokenResponse = await refreshToken()
            const newToken = newTokenResponse?.data.data.access_token

            originalRequest.headers ||= {}
            originalRequest.headers[NO_RETRY_HEADER] = 'true';
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`

            return await $axios(originalRequest)
          }
          return Promise.reject(error)

        default:
          return Promise.reject(error)
      }
    },
  )
}
