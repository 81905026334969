import { uniqueId } from 'lodash'
import { memo, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.scoped.css'

export interface TabTypes {
  name?: string
  value?: any
}

export interface TabsTypes {
  tabs: Array<TabTypes>

  selected?: any
  onTabUpdated?: any
}

function Tabs(props: TabsTypes) {
  const [currentTab, setCurrentTab] = useState(
    props.selected ? props.selected : props.tabs[0],
  )

  const handleClick = useCallback((tab: TabTypes) => {
    setCurrentTab(tab)
    if (props.onTabUpdated) props.onTabUpdated(tab)
  }, [])

  return (
    <div
      className="flex flex-col border-b border-slate-200"
      style={{
        marginBottom: '-1px',
      }}
    >
      <div className="flex flex-row px-6">
        {props.tabs?.map((tab: TabTypes) => {
          const isSelected = currentTab?.name == tab.name ? 'selected' : null
          return (
            <div
              key={`tab${uniqueId()}`}
              className={`tab flex flex-1 justify-center cursor-pointer`}
              onClick={() => {
                handleClick(tab)
              }}
            >
              <span className={`text-tab_title py-3 px-8 ${isSelected}`}>
                {tab.name}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(memo(Tabs))
