import './styles.css'

import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import ICON_TIMES from '../../assets/icons/times.svg'
import Button from '../button'
import { useEffect } from 'react'
import channelIO from '../../plugins/channelIO'

interface SheetPropTypes {
  active?: boolean
  position?: string
  title?: string
  description?: string
  content?: any
  completed?: any

  onHidden?: () => void

  submitText?: string
  onSubmit?: () => void
}

const Sheet = (props: SheetPropTypes) => {
  useEffect(() => {
    if (props.active) {
      channelIO.hide()
    } else {
      channelIO.show()
    }
    return () => {
      channelIO.show()
    }
  }, [props.active])

  if (!props.active) {
    return null
  }

  return (
    <>
      <div className={`sheet absolute flex flex-col inset-0`}>
        <div
          className="sheet-dimmer absolute inset-0"
          onClick={() => {
            if (props.onHidden) props.onHidden()
          }}
        />

        <div
          className={`sheet-window flex flex-col mt-auto bg-white animate__animated animate__slideInUp`}
        >
          <div className={`pt-8 pb-4 px-8`}>
            <div className="flex flex-row items-center">
              <div className="flex flex-col">
                <span className={`sheet-title-text`}>{props.title}</span>
                <span className={`sheet-description-text`}>
                  {props.description}
                </span>
              </div>
              <div
                className={`sheet-close-button ml-auto cursor-pointer`}
                onClick={() => {
                  if (props.onHidden) props.onHidden()
                }}
              >
                <img src={ICON_TIMES} alt="선택창 닫기" />
              </div>
            </div>
          </div>

          <div className="sheet-content">
            {props.content ? props.content() : null}
          </div>

          <div className={`flex flex-row px-8 mb-8`}>
            <Button
              className={`${props.completed ? 'primary' : ''} w-full py-2`}
              text={props.submitText ? `${props.submitText}` : '선택 완료'}
              onClick={() => {
                if (props.onSubmit) props.onSubmit()
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(Sheet)
