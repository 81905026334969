import "./styles.scoped.css";

import { useCallback, useRef, useState } from "react";
import { connect } from "react-redux";
import { mapDispatchProps, mapStateToProps } from "../../store/store";

import ICON_TIMES from "../../assets/icons/times.svg";
import ICON_ARROW_GRAY_DOWN from "../../assets/icons/arrow-down-gray.svg";
import ICON_CHECK from "../../assets/icons/check-gray.svg";
import ICON_CHECK_ACTIVE from "../../assets/icons/check-active.svg";

import { isEmpty, uniqueId } from "lodash";
import Sheet from "../sheet";

export interface InputPropTypes {
  label?: string;
  placeholder?: string;

  selected?: any;
  options?: any;

  bordered?: boolean;
  onUpdate?: (value?: string) => void;

  setCommon?: any;
}

function Select(props: InputPropTypes) {
  const inputRef = useRef<any>();
  const [selected, setSelected] = useState<any>();
  const [value, setValue] = useState<any>();
  const [isOverlayActive, setIsOverlayActive] = useState(false);

  const showOptions = () => {
    setIsOverlayActive(true);
  };

  const hideOptions = () => {
    setIsOverlayActive(false);
  };

  const selectOption = (option: any) => {
    setSelected(option);
  };

  const submitValue = () => {
    hideOptions();
    setValue(selected);
    if (props.onUpdate) props.onUpdate(selected);
  };

  const renderLabel = useCallback(() => {
    return props.label ? (
      <span className="label mb-1">{props.label}</span>
    ) : null;
  }, [props.label]);

  const renderInput = useCallback(() => {
    const isBordered = props.bordered ? "bordered" : null;
    const isContentEmpty = value ? null : "empty";

    return (
      <div
        ref={inputRef}
        className={`select ${isBordered} ${isContentEmpty} cursor-pointer flex flex-row`}
        onClick={() => {
          showOptions();
        }}
      >
        <div>
          <span className="option-label">
            {value ? value.name : props.placeholder}
          </span>
        </div>
        <div className="ml-auto justify-center items-center self-center">
          <img src={ICON_ARROW_GRAY_DOWN} />
        </div>
      </div>
    );
  }, [value]);

  const renderOverlay = () => {
    if (!isOverlayActive) {
      return null;
    }

    const isValueEmpty = isEmpty(selected) ? null : "selected";

    // return <Sheet 
    // title="기존 프로젝트 선택"
    // active={isOverlayActive}
    // content={()=>{
    //   return <div className="overlay-content flex flex-col gap-3 items-center mx-8 my-3">
    //         {props.options.map((item: any) => {
    //           const isSelected = item.id === selected?.id ? 'selected' : null

    //           return (
    //             <div
    //               key={`option${uniqueId()}`}
    //               className={`option w-full cursor-pointer ${isSelected}`}
    //               onClick={() => {
    //                 selectOption(item)
    //               }}
    //             >
    //               <div className="flex flex-row items-center">
    //                 <div>
    //                   <span className="text">{item.name}</span>
    //                 </div>
    //                 <div className="ml-auto">
    //                   <img
    //                     src={isSelected ? ICON_CHECK_ACTIVE : ICON_CHECK}
    //                     alt="비활성화"
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //           )
    //         })}
    //       </div>
    // }} />;
    return (
      <div className="absolute left-0 right-0 bottom-0 flex flex-col inset-0 ">
        <div
          className="absolute inset-0 dimmer"
          onClick={() => {
            hideOptions()
          }}
        />

        <div className="overlay flex flex-col mt-auto bg-white animate__animated animate__slideInUp">
          <div className="overlay-header pt-8 pb-4 px-8">
            <div className="flex flex-row items-center">
              <div>
                <span className="title">기존 프로젝트 선택</span>
              </div>
              <div
                className="close-button ml-auto cursor-pointer"
                onClick={hideOptions}
              >
                <img src={ICON_TIMES} alt="선택창 닫기" />
              </div>
            </div>
          </div>

          <div className="overlay-content flex flex-col gap-3 items-center mx-8 my-3">
            {props.options.map((item: any) => {
              const isSelected = item.id === selected?.id ? 'selected' : null

              return (
                <div
                  key={`option${uniqueId()}`}
                  className={`option w-full cursor-pointer items-center ${isSelected}`}
                  onClick={() => {
                    selectOption(item)
                  }}
                >
                  <div className="flex flex-row items-center">
                    <span className="text">{item.name}</span>
                    <div className="ml-auto">
                      <img
                        src={isSelected ? ICON_CHECK_ACTIVE : ICON_CHECK}
                        alt="비활성화"
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="overlay-submit flex flex-row" onClick={submitValue}>
            <div
              className={`submit-button w-full h-full cursor-pointer ${isValueEmpty}`}
            >
              <div className="flex flex-row w-full h-full justify-center items-center py-4">
                <span className="text">선택 완료</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };

  return (
    <div className="flex flex-col">
      {renderLabel()}
      {renderInput()}
      {renderOverlay()}
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchProps
)(Select);
