import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.scoped.css'

import ICON_FILE from '../../assets/icons/file.svg'
import ICON_TRASH from '../../assets/icons/trash.svg'

function File(props: any) {
  const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const handleDelete = (file: any) => {
    props.onDelete(file)
  }

  return (
    <div className="file flex items-center flex-row mb-3">
      <div className="mr-3">
        <img src={ICON_FILE} alt="파일 아이콘" />
      </div>
      <div className="flex flex-col">
        <span className="name">{props.file.name}</span>
        <span className="size">{formatBytes(props.file.size)}</span>
      </div>
      {props.onDelete ? (
        <div
          className="ml-auto cursor-pointer"
          onClick={() => handleDelete(props.file)}
        >
          <img src={ICON_TRASH} alt="삭제" />
        </div>
      ) : null}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(File)
