import './styles.css'

import API from '../../api'
import { AxiosResponse } from 'axios'
import { connect } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { mapDispatchProps, mapStateToProps } from '../../store/store'
import { uniqueId } from 'lodash'

import ICON_BACK from '../../assets/icons/arrow-left-black.svg'
import ICON_DOCUMENT from '../../assets/icons/document-plus-white.svg'
import ICON_LINK from '../../assets/icons/link-gray.svg'
import ICON_MONITOR from '../../assets/icons/monitor-gray.svg'
import ICON_COPY from '../../assets/icons/copy-gray.svg'
// import '@google/model-viewer'

// Components
import Button from '../../components/button'
import { ICON_POSITIONS } from '../../components/button'

import Input from '../../components/input'
import { TYPE as INPUT_TYPES } from '../../components/input'

import Slider from 'react-slick'
import FileCard from '../../components/file-card'
import Sheet from '../../components/sheet'
import Alert from '../../plugins/swal'

const GroupIndex = (props: any) => {
  const { state } = useLocation()

  const navigate = useNavigate()
  const [files, setFiles] = useState<any>()
  const [filesToAdd, setFilesToAdd] = useState<any>([])
  const [isFileAddSheetOpened, setIsFileAddSheetOpened] = useState(false)
  const [isShareSheetOpened, setIsShareSheetOpened] = useState(false)
  const [isDraggable, setIsDraggable] = useState(true)

  const shareLink = useRef()
  const sharePassword = useRef()

  const fetchFiles = () => {
    API.File.list(
      {
        projectId: state.projectId,
        groupId: state.groupId,
      },
      props.auth.token,
    )
      .then((response: AxiosResponse) => {
        const files = response.data.data.map((file: any) => {
          return { ...file, ...{ name: file.originalName } }
        })

        setFiles(files)
      })
      .catch((error: any) => {
        Alert.fire({ text: error.response?.data?.message, icon: 'error' })
      })
  }

  useEffect(() => {
    fetchFiles()
  }, [])

  const renderBreadCrumbs = () => {
    return (
      <div className="flex flex-row">
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate(-1)
          }}
        >
          <img src={ICON_BACK} alt="뒤로가기" />
        </div>
      </div>
    )
  }

  const renderPageGuide = () => {
    return (
      <div className="flex flex-col gap-1">
        <span className="group-guide-text">
          {state.title ? state.title : '업로드 파일을 확인해주세요'}
        </span>
        {state.description ? (
          <span className="group-guide-description">{state.description}</span>
        ) : null}
      </div>
    )
  }

  const sliderRef = useRef<any>()
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.addEventListener(
        'touchmove',
        function (e: any) {
          // e.preventDefault()
        },
        { passive: false },
      )
    }
  }, [sliderRef.current])

  const renderGroupSlider = () => {
    const settings = {
      className: 'center',
      centerMode: true,
      infinite: false,
      centerPadding: '24px',
      slidesToShow: 1,
      speed: 500,
      accessibility: true,
      draggable: isDraggable,
      cancelable: true,
    }

    return (
      <Slider {...settings} className="card-slider flex flex-row">
        {files
          ? files.map((file: any) => {
              return (
                <FileCard
                  onPointerEnter={() => {setIsDraggable(false)}}
                  onPointerLeave={() => {setIsDraggable(true)}}
                  onTouchStart={()=>{setIsDraggable(false)}}
                  onMouseDown={()=>{setIsDraggable(false)}}
                  onTouchEnd={()=>{setIsDraggable(true)}}
                  onMouseUp={()=>{setIsDraggable(true)}}
                  key={`file${uniqueId()}`}
                  file={file}
                  groupId={state.groupId}
                />
              )
            })
          : null}
      </Slider>
    )
  }

  const storeMoreFiles = () => {
    if (
      files &&
      files.length &&
      filesToAdd &&
      filesToAdd.length &&
      files.length + filesToAdd.length > 3
    ) {
      Alert.fire({ text: '총 3개 이상의 시안을 업로드 할 수 없습니다.' })
      return false
    }

    API.File.store(
      {
        projectId: state.projectId,
        groupId: state.groupId,
        files: Array.from(filesToAdd),
      },
      props.auth.token,
    )
      .then((response: AxiosResponse) => {
        setIsFileAddSheetOpened(false)

        API.File.list(
          {
            projectId: state.projectId,
            groupId: state.groupId,
          },
          props.auth.token,
        )
          .then((response: AxiosResponse) => {
            fetchFiles()

            Alert.fire({
              text: '성공적으로 시안을 추가하였습니다',
              icon: 'success',
            })
          })
          .catch((error: any) => {
            Alert.fire({ text: error.response?.data?.message, icon: 'error' })
          })
      })
      .catch((error: any) => {
        Alert.fire({ text: error.response?.data?.message, icon: 'error' })
      })
  }

  const renderAddFileSheet = () => {
    return (
      <Sheet
        active={isFileAddSheetOpened}
        title="시안 추가하기"
        description="함께 확인하고 싶은 3D 파일을 업로드해주세요"
        onHidden={() => {
          setIsFileAddSheetOpened(false)
        }}
        content={() => {
          return (
            <div className="overlay-content flex flex-col gap-3 mx-8 pt-3 pb-3">
              <Input
                className="py-10"
                type={INPUT_TYPES.FILE}
                append={true}
                maxLength={2}
                onUpdate={(event: any, value: any) => {
                  setFilesToAdd(value)
                }}
              />
            </div>
          )
        }}
        completed={filesToAdd.length ? true : false}
        submitText={`파일 업로드 완료`}
        onSubmit={() => {
          storeMoreFiles()
        }}
      />
    )
  }

  const renderShareSheet = () => {
    const handleCopyClipBoard = async (text: string) => {
      try {
        await navigator.clipboard.writeText(text)

        Alert.fire({
          text: '클립보드에 링크가 복사되었습니다',
          icon: 'success',
        })
      } catch (e) {
        Alert.fire({
          text: '복사에 실패하였습니다',
          icon: 'warning',
        })
      }
    }

    return (
      <Sheet
        active={isShareSheetOpened}
        title="공유 링크 생성"
        description="클라이언트나 동료에게 링크로 전달해보세요"
        completed={true}
        content={() => {
          return (
            <div className="sheet-share flex flex-col gap-3">
              <div className="flex flex-col gap-3 pb-10 px-8">
                <div className="border-t border-slate-300" />
                <div className="flex flex-row">
                  <div className="flex flex-col gap-1  w-[90%]">
                    <div className="flex flex-row inline-block">
                      <span
                        className={`group-guide-item-title flex text mr-3 truncate`}
                      >
                        링크
                      </span>
                      <span className="truncate block">
                        {shareLink.current}
                      </span>
                    </div>
                    <div className="flex flex-row inline-block">
                      <span
                        className={`group-guide-item-title flex text mr-3 truncate`}
                      >
                        비밀번호
                      </span>
                      <span className="truncate block">
                        {sharePassword.current}
                      </span>
                    </div>
                  </div>

                  <div
                    className="flex flex-col flex-1 justify-center items-center cursor-pointer"
                    onClick={() => {
                      handleCopyClipBoard(
                        `${shareLink.current}?password=${sharePassword.current}`,
                      )
                    }}
                  >
                    <img src={ICON_COPY} alt="" />
                  </div>
                </div>
                <div className="border-b border-slate-300" />
              </div>
            </div>
          )
        }}
        onHidden={() => {
          setIsShareSheetOpened(false)
        }}
        submitText="공유하기"
        onSubmit={() => {
          if (navigator.share) {
            navigator
              .share({
                title: 'TOSPACE',
                text:
                  `클라이언트나 동료에게 링크로 전달해보세요.${'\n'}` +
                  `비밀번호 : ${sharePassword.current}`,
                url: `${shareLink.current}`,
              })
              .then(() => console.log('공유 성공'))
              .catch((error) => console.log('공유 실패', error))
          } else {
            handleCopyClipBoard(
              `${shareLink.current}?password=${sharePassword.current}`,
            )
          }
        }}
      />
    )
  }

  return (
    <div className="page-group page-container flex flex-col justify-between h-full animate__animated animate__fadeIn bg-[#F6F8F9]">
      <div className="flex flex-col p-6">
        <div className="flex flex-row">{renderBreadCrumbs()}</div>
        <div className="flex flex-row mt-4">{renderPageGuide()}</div>
      </div>

      <div className="py-6">{renderGroupSlider()}</div>
      {state.add && files?.length < 3 ? (
        <div className="flex flex-row p-6">
          <Button
            className="w-full active py-3"
            text="시안 추가"
            icons={[
              {
                position: ICON_POSITIONS.LEFT,
                icon: () => {
                  return <img src={ICON_DOCUMENT} alt="시안추가 아이콘" />
                },
              },
            ]}
            onClick={() => {
              setIsFileAddSheetOpened(true)
              setFilesToAdd([])
            }}
          />
        </div>
      ) : null}

      <div className="flex flex-row gap-3 px-6 pb-8">
        {state.share ? (
          <Button
            className="w-full neumorphism px-3 py-4"
            text="링크 공유"
            icons={[
              {
                position: ICON_POSITIONS.LEFT,
                icon: () => {
                  return <img src={ICON_LINK} alt="" />
                },
              },
            ]}
            onClick={() => {
              API.Group.share(
                {
                  projectId: state.projectId,
                  groupId: state.groupId,
                },
                props.auth.token,
              )
                .then((response: AxiosResponse) => {
                  shareLink.current = response.data.data.link
                  sharePassword.current = response.data.data.password
                  setIsShareSheetOpened(true)
                })
                .catch((error: any) => {
                  Alert.fire({
                    text: error.response?.data?.message,
                    icon: 'error',
                  })
                })
            }}
          />
        ) : null}

        <Button
          className="w-full neumorphism px-3 py-4"
          text="회의 모드"
          icons={[
            {
              position: ICON_POSITIONS.LEFT,
              icon: () => {
                return <img src={ICON_MONITOR} alt="" />
              },
            },
          ]}
          onClick={() => {
            Alert.fire({ icon: 'info', text: '서비스 준비중입니다' })
          }}
        />
      </div>

      {renderAddFileSheet()}
      {renderShareSheet()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(GroupIndex)
