import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.scoped.css'

function Welcome(props: any) {
  const getToday = () => {
    const today = new Date()
    let dayName = ''

    switch (today.getDay()) {
      case 0:
        dayName = '일요일'
        break
      case 1:
        dayName = '월요일'
        break
      case 2:
        dayName = '화요일'
        break
      case 3:
        dayName = '수요일'
        break
      case 4:
        dayName = '목요일'
        break
      case 5:
        dayName = '금요일'
        break
      case 6:
        dayName = '토요일'
        break
      case 7:
        dayName = '일요일'
        break
    }

    return (
      `${today.getFullYear()}년 ` +
      `${today.getMonth() + 1}월 ` +
      `${today.getDate()}일 ` +
      `${dayName}`
    )
  }

  return (
    <div className="flex flex-col px-6">
      <span className="txt-today-date mb-0.5">{getToday()}</span>
      <span className="txt-main-title">
        {props.user.name}님, 만나서 반갑습니다.
      </span>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(Welcome)
