/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable quotes */
import { snakelize, camelize } from "../../../utils/object";

export default ($axios: any): any => ({
  // 목록
  async list(data: any, token: string) {
    return camelize(
      await $axios.get(
        `/group/list/${data.projectId}`,
        snakelize({
          params: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      )
    );
  },

  // 공유
  async share(data: any, token: string) {
    return camelize(
      await $axios.post(
        `/group/link/${data.projectId}/${data.groupId}`,
        snakelize({
          data,
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );
  },

  // 비밀번호 확인
  async auth(data: any) {
    return camelize(
      await $axios.get(`/group/${data.groupId}/auth/${data.password}`)
    );
  },
});
