import './index.css'
import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'
import React from 'react'

function BlankLayout(props: any) {
  return (
    <React.Fragment>
      <div
        className="layout-blank animate__animated animate__fadeIn"
        style={{ background: 'rgba(0,0,0,0.075)' }}
      >
        <div className="layout-content w-full h-full bg-white">
          <slot>{props.children}</slot>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(BlankLayout)
