import { connect } from "react-redux";
import { mapDispatchProps, mapStateToProps } from "../../store/store";
import React, { useEffect, useRef, useState } from "react";
import { Oval } from "react-loader-spinner";

interface LoaderPropTypes {
  isVisible?: boolean;
}

const Loader = (props: LoaderPropTypes) => {
  const loaderRef = useRef<HTMLDivElement | undefined | any>(null);

  return props.isVisible ? (
    <div
      ref={loaderRef}
      className="absolute flex flex-col justify-center items-center inset-0"
    >
      <div className="w-full h-full bg-black opacity-30" />
      <div className="absolute">
        <Oval
          height={80}
          width={80}
          color="#0E73F6"
          // wrapperStyle={{ backgroundColor: "white" }}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#0E73F6"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    </div>
  ) : null;
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(Loader);
