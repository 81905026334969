/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable quotes */

import { snakelize, camelize } from "../../../utils/object";

const headers = {
  headers: { "Content-Type": `application/json` },
};

export default ($axios: any): any => ({
  // 로그인
  async login(data: any) {
    return camelize(await $axios.post(`/auth/login`, snakelize(data), headers));
  },

  // 가입
  async register(data: any) {
    return camelize(
      await $axios.post(`/auth/register`, snakelize(data), headers)
    );
  },

  async refresh(data?: any) {
    return camelize(await $axios.get("/auth/refresh"));
  },
});
