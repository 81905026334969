import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

import './styles.scoped.css'

// icons
import ICON_AR from '../../assets/icons/ar-white.svg'
import { useNavigate } from 'react-router-dom'
import { SCREENS } from '../../routes'
import { useRef, useState } from 'react'

interface FileCardPropTypes {
  onPointerEnter?: any
  onPointerLeave?: any
  onTouchStart?: any
  onTouchEnd?: any
  onMouseDown?: any
  onMouseUp?: any

  file?: {
    glbLink?: string
    usdzLink?: string
    thumbnail?: string
    originalName?: string
    createdAt: string
    files: Array<any>
  }
  groupId: string
}

function FileCard(props: FileCardPropTypes) {
  const navigate = useNavigate()
  const modelRef = useRef<HTMLDivElement | any>()

  const renderModel = () => (
    <model-viewer
      ar={false}
      class="w-[100%] h-[100%]"
      src={props?.file?.glbLink}
      ios-src={props?.file?.usdzLink}
      poster={props?.file?.thumbnail}
      alt="3D 모델"
      shadow-intensity="1"
      camera-controls
      touch-action="pan-y"
    >
        <button slot="ar-button" style={{ opacity: 0, display: "none" }} />
        <button
          slot="default-ar-button"
          style={{ opacity: 0, display: "none" }}
        />
    </model-viewer>
  )

  // useEffect(() => {
  //   if (modelRef.current)
  //     modelRef.current.addEventListener(
  //       'touchmove',
  //       (event: any) => {
  //         event.preventDefault()
  //         console.log('touch-move')
  //       },
  //       { passive: false },
  //     )

  //   return () => {
  //     if (modelRef.current) modelRef.current.removeEventListener('touchmove')
  //   }
  // }, [modelRef.current])

  const handlePointerEnter = (event: any) => {
    if (props.onPointerEnter) props.onPointerEnter(event)
  }

  const handlePointerLeave = (event: any) => {
    if (props.onPointerLeave) props.onPointerLeave(event)
  }

  const handleDrag = (event: any) => {
    // event.stopPropagation()
  }

  const [clickPosition, setClickPosition] = useState<any>()

  const handleTouchStart = (event: any) => {
    const firstTouchEvent = event.touches[0]
    const location = {
      x: firstTouchEvent.clientX,
      y: firstTouchEvent.clientY,
    }

    setClickPosition(location)
  }

  const handleClickStart = (event: any) => {
    const clickEvent = event.nativeEvent
    const location = {
      x: clickEvent.clientX,
      y: clickEvent.clientY,
    }

    setClickPosition(location)
  }

  const handleClickEnd = (event: any) => {
    const clickEvent = event.nativeEvent
    const location = {
      x: clickEvent.clientX,
      y: clickEvent.clientY,
    }

    const differences = {
      x: Math.abs(clickPosition.x - location.x),
      y: Math.abs(clickPosition.y - location.y),
    }

    if (differences.x < 50 && differences.y < 50) {
      handleClick()
    }
  }

  const handleTouchEnd = (e: any) => {
    var touch = e.changedTouches[0]

    const firstTouchEvent = touch
    const location = {
      x: firstTouchEvent.clientX,
      y: firstTouchEvent.clientY,
    }

    const differences = {
      x: Math.abs(clickPosition.x - location.x),
      y: Math.abs(clickPosition.y - location.y),
    }

    if (differences.x < 10 && differences.y < 10) {
      handleClick()
    }
  }

  const handleClick = () => {
    if (!props.file) {
      return false
    }
    navigate(SCREENS.FILE.PATH, {
      state: {
        glbLink: props.file.glbLink,
        usdzLink: props.file.usdzLink,
        thumbnail: props.file.thumbnail,
        groupId: props.groupId,
      },
    })
  }

  return (
    <div className="file-card ">
      
      <div className='relative'>
        <div className="thumbnail-container p-6" 
        onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave} >
          <div
            ref={modelRef}
            className="thumbnail flex justify-center items-center"
          >
            {renderModel()}
          </div>
        </div>

        <div className='absolute top-0 h-[30%] w-[100%]'></div>
        <div className='absolute bottom-0 h-[30%] w-[100%]'></div>
      </div>
      <div
        className="preview-button flex flex-row justify-center px-6 cursor-pointer"
        onTouchStart={(event) => {
          handleTouchStart(event)
        }}
        onTouchEnd={(event) => {
          handleTouchEnd(event)
        }}
        onMouseDown={(event) => {
          handleClickStart(event)
        }}
        onMouseUp={(event) => {
          handleClickEnd(event)
        }}
      >
        <div
          className="flex flex-row py-4 items-center"
          
        >
          <div>
            <img src={ICON_AR} alt="AR 아이콘" />
          </div>
          <div className="text mx-1">
            <span className="">AR 보기</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(FileCard)
