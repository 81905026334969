import "./index.css";
import { connect } from "react-redux";
import { mapDispatchProps, mapStateToProps } from "../../store/store";

import Footer from "../../components/footer";
import { useCallback, useEffect, useRef, useState } from "react";
import Loader from "../../components/loader";

function IndexLayout(props: any) {
  const footerRef = useRef<any>();
  const [footerHeight, setFooterHeight] = useState<any>(0);

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.clientHeight);

      if (footerRef.current.clientHeight !== props.common.footerHeight) {
        props.setCommon({ footerHeight: footerRef.current.clientHeight });
      }
    }
  }, [footerRef.current]);

  const renderLayout = useCallback(() => {
    return (
      <div
        className="layout-index flex flex-col justify-between"
        style={{ background: "rgba(0,0,0,0.075)" }}
      >
        <div className="layout-content absolute bottom-0 left-0 right-0 bg-white">
          <div className="relative mt-auto" ref={footerRef}>
            <Footer />
          </div>
        </div>

        <div className="layout-content flex flex-col w-full h-full bg-white">
          {footerRef.current ? (
            <div
              className="flex flex-col overflow-scroll"
              style={{
                height: `calc(100% - ${footerHeight}px)`,
              }}
            >
              <slot>
                <div className="w-full h-full animate__animated animate__fadeIn">
                  {props.children}
                </div>
              </slot>
            </div>
          ) : null}
        </div>
      </div>
    );
  }, [footerHeight]);

  return renderLayout();
}

export default connect(
  mapStateToProps,
  mapDispatchProps
)(IndexLayout);
