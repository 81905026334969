import './index.css'
import { connect } from 'react-redux'
import { mapDispatchProps, mapStateToProps } from '../../store/store'

function AuthLayout(props: any) {
  return (
    <>
      <div className="layout-auth animate__animated animate__fadeIn">
        <div className="layout-content w-full h-full">
          <slot>{props.children}</slot>
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchProps)(AuthLayout)
